module.exports = {
  'contact': 'Kontakt',
  'about_us': 'Impressum',
  'privacy': 'Datenschutz',
  'work_title': 'Work',
  'work_description': 'Wir arbeiten als Team mit unseren Kunden und bauen Websites, die begeistern.',
  'services_title': 'Services',
  'services_description': 'Wir können alles, was mit Web zu tun hat. Und mit alles meinen wir wirklich alles.',
  'about_title': 'Über uns',
  'about_description': 'Wir sind eine Full-Service-Webagentur mit Sitz in Karlsruhe.',
  'project_ama_architekten_description': 'Strategie & Beratung, Branding, UX & UI Design, Responsive Website',
  'project_yourproject_description': 'Strategie & Beratung, Branding, UX & UI Design, Responsive Website',
  'project_steele_meals_description': 'Strategie & Beratung, Branding, UX & UI Design, Online-Shop',
  'project_baubranche_jobs_description': 'Strategie & Beratung, Branding, UX & UI Design, Responsive Website',
  'project_my_source_nutrition_description': 'Strategie & Beratung, Branding, UX & UI Design, Online-Shop',
  'project_hellmann_description': 'Strategie & Beratung, UX & UI Design, Webanwendung',
  'to_the_page': 'Zur Seite',
  'project_kickass_description': 'Strategie & Beratung, Branding, UX & UI Design, Online-Shop',
  'project_philipp_seipp_description': 'Strategie & Beratung, Branding, UX & UI Design, Responsive Website',
  'contact_description': 'Bereit für dein nächstes Webprojekt mit uns?',
  'services': 'Services',
  'work': 'Work',
  'about': 'Über Uns',
  'service1_title': 'Strategie & Beratung',
  'service1_description': 'Bevor wir mit der eigentlichen Umsetzung anfangen, entwickeln wir zusammen mit dir einen Aktionsplan für deine Nutzer- und Markenerlebnisse.',
  'service2_title': 'UX & UI Design',
  'service2_description': 'Als UX/UI Designer kreieren wir Produkte und Services, die sich durch eine hervorragende Benutzung auszeichnen und ein Wow auslösen.',
  'service3_title': 'Websites & Webapps',
  'service3_description': 'Ob Frontend oder Backend, Angular oder React, Website oder App – wir können Full-Stack und verwenden nur die modernsten Technologien.',
  'service4_title': 'E-Commerce & Shops',
  'service4_description': 'Wir bauen Online-Shops und entwickeln stabile Prozesse. Magento, Shopify, WooCommerce, Stripe oder PayPal – hiermit kennen wir uns bestens aus.',
  'service_large': 'Wir lieben das Web und noch mehr lieben wir einmalige Webseiten. Ob Strategie, Design oder Entwicklung, wir haben alles, was es hierfür braucht.',
  'learn_more': 'Mehr erfahren',
  'all_rights_reserved': 'Alle Rechte vorbehalten.',
  'headline': 'Als Full-Service-Webagentur entwickeln wir ',
  'headline1': 'die Nutzererlebnisse von morgen<span class="black">.</span>',
  'headline2': 'Webseiten, die ein Wow auslösen<span class="black">.</span>',
  'headline3': 'Apps, die einfach zu benutzen sind<span class="black">.</span>',
  'headline4': 'Online-Shops, die Spaß machen<span class="black">.</span>',
  'ecommerce': 'E-Commerce',
  'uxdesign': 'UX/UI Design',
  'websites': 'Websites',
  'your_offer': 'Dein Angebot',
  'show_more': 'Mehr anzeigen',
  'show_less': 'Weniger anzeigen',
  'create_contact': 'Kontakt aufnehmen',
  'f_desc': 'Wir sind eine Full-Service Agentur mit Sitz in Karlsruhe und haben uns auf die Entwicklung von einmaligen User Experiences spezialisiert.',
  'agency': 'Agentur',
  'links': 'Links',
  'legal': 'Legal',
  'social': 'Social',
  'ux_ui_design': 'UX/UI Design'
};
